exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-loqtorzi-clinical-trial-results-jsx": () => import("./../../../src/pages/loqtorzi-clinical-trial-results.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-clinical-trial-results-jsx" */),
  "component---src-pages-loqtorzi-side-effects-jsx": () => import("./../../../src/pages/loqtorzi-side-effects.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-side-effects-jsx" */),
  "component---src-pages-loqtorzi-solutions-jsx": () => import("./../../../src/pages/loqtorzi-solutions.jsx" /* webpackChunkName: "component---src-pages-loqtorzi-solutions-jsx" */),
  "component---src-pages-receiving-treatment-jsx": () => import("./../../../src/pages/receiving-treatment.jsx" /* webpackChunkName: "component---src-pages-receiving-treatment-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-stay-in-touch-index-jsx": () => import("./../../../src/pages/stay-in-touch/index.jsx" /* webpackChunkName: "component---src-pages-stay-in-touch-index-jsx" */),
  "component---src-pages-stay-in-touch-thank-you-jsx": () => import("./../../../src/pages/stay-in-touch/thank-you.jsx" /* webpackChunkName: "component---src-pages-stay-in-touch-thank-you-jsx" */),
  "component---src-pages-what-is-loqtorzi-jsx": () => import("./../../../src/pages/what-is-loqtorzi.jsx" /* webpackChunkName: "component---src-pages-what-is-loqtorzi-jsx" */),
  "component---src-pages-what-is-nasopharyngeal-cancer-jsx": () => import("./../../../src/pages/what-is-nasopharyngeal-cancer.jsx" /* webpackChunkName: "component---src-pages-what-is-nasopharyngeal-cancer-jsx" */)
}

